import Container from "../components/Container";
import { useState } from "react";
import { IFiveElementsInterator } from '../classes/interfaces/IFiveElementsInterator';
import HeaderEn from "../components/HeaderEn";
import { FiveElementsHU } from "../classes/FiveElementsHU";


const fiveElements = new FiveElementsHU();
const shengCycle = fiveElements.GetSHENGCycle();
const koCycle = fiveElements.GetKOCycle();

const FiveElementsPageHU = () => {
  const [iterator, setIterator] = useState<IFiveElementsInterator>(shengCycle);
  const [element, setElement] = useState(shengCycle.Current());
  const [interv, setInterv] = useState();

  const iteratorHandler = (iterator: IFiveElementsInterator) => () => {
    setIterator(iterator);
  }

  const nextHandler = () => {
    iterator.Next();
    setElement(iterator.Current());
  }

  const autoHandler = () => {
    const nIntervId: any = setInterval(nextHandler, 10000);
    setInterv(nIntervId); 
  }

  const resetHandler = () => {
    clearInterval(interv);
    iterator.Rewind();
    setElement(iterator.Current());
  }
  
  return (
    <div>
      <HeaderEn />
      <Container>
        <div className="d-grid gap-2 col-6 mx-auto">
          <button onClick={iteratorHandler(shengCycle)} className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#fiveElementsViewModal">
            SHENG Ciklus
          </button>
          <button onClick={iteratorHandler(koCycle)} className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#fiveElementsViewModal">
            KO Ciklus
          </button>
        </div>

        <div style={{backgroundColor: element.colorCode}} className="modal fade" id="fiveElementsViewModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" style={{height: '100%'}}>
            <div style={{backgroundColor: element.colorCode, height: '100%'}} className="modal-content">
              
              <div className="modal-header" style={{borderBottom: 'none'}}>
                {/* <h1 className="modal-title fs-5" id="exampleModalLabel">{element.name}</h1> */}
                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              </div>
              <div className="modal-body"></div>
              <div className="modal-footer" style={{borderTop: 'none'}}>
                <div className="btn-group" role="group" aria-label="Basic example">
                  <button type="button" onClick={resetHandler} data-bs-dismiss="modal" aria-label="Close" className="btn btn-outline-secondary btn-lg">Bezár</button>
                  <button type="button" onClick={autoHandler} className="btn btn-outline-secondary btn-lg">Auto 10s</button>
                  <button type="button" onClick={nextHandler} className="btn btn-secondary btn-lg">Következő</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>      
    </div>
    
  );
}

export default FiveElementsPageHU;