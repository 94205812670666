import Result from "../../models/Result";
import IView from "../interfaces/IView";


export class ResultPhrasesEnUS implements IView {
  constructor(private wrapperElement: HTMLElement) {

  }

  private element = (result: Result): string => {
    let tudatosBal: string = result.conscious.left.name;
    let tudatosJobb: string = result.conscious.right.name;
    let tudatalattiBal: string = result.subconscious.left.name;
    let tudatalattiJobb: string = result.subconscious.right.name;
    let testiBal: string = result.physical.left.name;
    let testiJobb: string = result.physical.right.name;

    return `<ul class="list-group">
      <li class="list-group-item list-group-item-warning">In this case, you have longed since childhood to (VB physical Left) <b>${testiBal}</b></li>
      <li class="list-group-item list-group-item-warning">Instead, you found that (VB physical Right) <b>${testiJobb}</b></li>
      <li class="list-group-item list-group-item-info">In the past, you wanted to (VB Subconscious Left Side) <b>${tudatalattiBal}</b></li>
      <li class="list-group-item list-group-item-info">Instead, you found that (VB Subconscious Right) <b>${tudatalattiJobb}</b></li>
      <li class="list-group-item list-group-item-danger">Because of this, in the present you long to (VB Conscious Left) <b>${tudatosBal}</b></li>
      <li class="list-group-item list-group-item-danger">But you find that (VB Conscious Right) <b>${tudatosJobb}</b></li>
    </ul>`
  } 


  render = (result: Result): void => {
    this.wrapperElement.innerHTML = this.element(result);
  }

  reset = (): void => {
    this.wrapperElement.innerHTML = '';
  }

}