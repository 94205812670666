import Result from "../../models/Result";
import IView from "../interfaces/IView";

export class ResultTableEnUS implements IView {
  constructor(private wrapperElement: HTMLElement) {

  }
  

  private element = (result: Result): string => {
    let tudatosBal: string = result.conscious.left.name;
    let tudatosJobb: string = result.conscious.right.name;
    let tudatalattiBal: string = result.subconscious.left.name;
    let tudatalattiJobb: string = result.subconscious.right.name;
    let testiBal: string = result.physical.left.name;
    let testiJobb: string = result.physical.right.name;

    return `<table class="table">
    <thead>
      <tr>
        <th scope="col">Levels</th>
        <th scope="col">Left</th>
        <th scope="col">Right</th>
      </tr>
    </thead>
    <tbody>
      <tr class="table-danger">
        <th scope="row">1. conscious</th>
        <td>${tudatosBal}</td>
        <td>${tudatosJobb}</td>
      </tr>
      <tr class="table-info">
        <th scope="row">2. subconscious</th>
        <td>${tudatalattiBal}</td>
        <td>${tudatalattiJobb}</td>
      </tr>
      <tr class="table-warning">
        <th scope="row">3. physical</th>
        <td>${testiBal}</td>
        <td>${testiJobb}</td>
      </tr>
    </tbody>
    </table>`
  } 

  render = (result: Result): void => {
    this.wrapperElement.innerHTML = this.element(result);
  }

  reset = (): void => {
    this.wrapperElement.innerHTML = '';
  }
}