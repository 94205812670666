import { useState } from "react";
import { IFormat } from "../classes/interfaces/IFormat";
import Container from "../components/Container";
import Header from "../components/Header";

const formats: IFormat[] = [
  {name: "Szubkortikális", format: "Anatómia x Mirigy x IM 26"},
  {name: "Thalamus retikuláris mag", format: "Anatómia x Mirigy x IM 26 x IM 23 + KM 15"},
  {name: "Amygdala", format: "Anatómia x Mirigy x KM 23 x KM 24 + KM 14"},
  {name: "Kortex", format: "Anatómia x Mirigy x KM 24"},
  {name: "Limbikus rendszer", format: "Anatómia x Mirigy x KM 23 x KM 24"},
  {name: "Hippocampus", format: "Anatómia x Mirigy x KM 23 x KM 24 + KM 17"},
  {name: "Stria terminalis beágyazódó nucleusa", format: "Anatómia x Mirigy x KM 23 x KM 24 + IM 17"},
  {name: "Gyrus cinguli", format: "Anatómia x Mirigy x KM 23 x KM 24 + KM 21"},
  {name: "Gyrus parahippocampalis", format: "Anatómia x Mirigy x KM 23 x KM 24 + KM 20"},
  {name: "Köztiagy", format: "Anatómia x Mirigy x IM 23 x IM 26"},
  {name: "Tobozmirigy", format: "Anatómia x Mirigy x IM 23 x IM 26 + IM 20"},
  {name: "Agytörzs", format: "Anatómia x Mirigy x IM 26 x IM 26"},
  {name: "(Agytörzs) RAS", format: "Anatómia x Mirigy x IM 26 x IM 26 + IM 16"},
  {name: "Középagy", format: "Anatómia x Mirigy x IM 24 x IM 26"},
  {name: "(Középagy) RAS", format: "Anatómia x Mirigy x IM 24 x IM 26 + IM 16"},
  {name: "(Középagy) PAG", format: "Anatómia x Mirigy x IM 24 x IM 26 + IM 14"},
  {name: "Híd", format: "Anatómia x Mirigy x IM 26 x IM 24,5"},
  {name: "Híd RAS", format: "Anatómia x Mirigy x IM 26 x IM 24,5 + IM 16"},
  {name: "Híd Kisagy (Cerebellum)", format: "Anatómia x Mirigy x IM 26 x IM 24,5 + IM 15"},
  {name: "Nyúltvelő", format: "Anatómia x Mirigy x IM 26 x IM 25"},
  {name: "Nyúltvelő RAS", format: "Anatómia x Mirigy x IM 26 x IM 25 + IM 16"},
  {name: "Nyúltvelő Area postrema (Törésközpont)", format: "Anatómia x Mirigy x IM 26 x IM 25 + IM 23"},
  {name: "Gerincvelő", format: "Anatómia x Mirigy x IM 26 x IM 28"},
  {name: "Szív", format: "Anatómia x Mirigy x Sz AP"},
  {name: "Neuron", format: "Sejt x Neurotranszmitter"},
  {name: "Epehólyag", format: "Anatómia x Mirigy x Eh AP"},
  {name: "Vastagbél", format: "Anatómia x Mirigy x Vab AP"},
  {name: "Hasnyálmirigy", format: "Anatómia x Mirigy x Lép AP"},
  {name: "Vékonybél", format: "Anatómia x Mirigy x Véb. AP"},
  {name: "Gyomor", format: "Anatómia x Mirigy x Gy AP"},
  {name: "Nyelőcső (az egész)", format: "Anatómia x Mirigy x Gyomor 9 x Gyomor 10 x Gyomor 11"},
  {name: "Nyelőcső (felső szakasz)", format: "Anatómia x Mirigy x Gyomor 9"},
  {name: "Nyelőcső (középső szakasz)", format: "Anatómia x Mirigy x Gyomor 10"},
  {name: "Nyelőcső (alsó szakasz)", format: "Anatómia x Mirigy x Gyomor 11"},
];

let foundFormats: IFormat[] = [];

const Formats = () => {
  const [searchInput, setSearchInput] = useState("");

  const changeHandle = (e: any) => {
    e.preventDefault();
    setSearchInput(e.target.value);

    foundFormats = formats.filter((format) => {
      return format.name.toLowerCase().includes(e.target.value.toLowerCase());
    });

    if (e.target.value === "") {
      foundFormats = [];
    }
  }


  return (
    <div>
      <Header />
      <Container>
      <div className="card">
        <div className="card-body">
          <div className="form-floating">
            <input 
              onChange={changeHandle}
              value={searchInput}
              type="text" 
              className="form-control" 
              id="search" 
              placeholder="Password" 
            />
            <label htmlFor="search">Search</label>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
        <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Format</th>
          </tr>
        </thead>
        <tbody>
          {foundFormats.map((format, index) => (
            <tr key={index+1}>
              <th scope="row">{index+1}</th>
              <td>{format.name}</td>
              <td>{format.format}</td>
            </tr>
          ))}
                  
        </tbody>
      </table>
        </div>
      </div>
      
      </Container>
    </div>
  );
}

export default Formats;