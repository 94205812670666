import { IElement } from '../models/IElement';
import { KOCycle } from './KOCycle';
import { SHENGCycle } from './SHENGCycle';
import { IFiveElements } from "./interfaces/IFiveElements";
import { IFiveElementsInterator } from "./interfaces/IFiveElementsInterator";

export class FiveElementsHU implements IFiveElements {
  private elements: IElement[] = [
    {name: 'fa', colorName: 'zöld', colorCode: '#50C878'},
    {name: 'tűz', colorName: 'vörös', colorCode: '#D2042D'},
    {name: 'föld', colorName: 'sárga', colorCode: '#FFFF00'},
    {name: 'fém', colorName: 'fehér', colorCode: 'white'},
    {name: 'víz', colorName: 'kék', colorCode: '#0096FF'}
  ];

  GetSHENGCycle(): IFiveElementsInterator {
    return new SHENGCycle(this.elements);
  }

  GetKOCycle(): IFiveElementsInterator {
    return new KOCycle(this.elements);
  }

  Fire(): IElement {
    const selected = this.elements.filter(e => {
      return e.colorName === 'fire';
    })
    return selected[0];
  }

  Wood(): IElement {
    const selected = this.elements.filter(e => {
      return e.colorName === 'wood';
    })
    return selected[0];
  }

  Water(): IElement {
    const selected = this.elements.filter(e => {
      return e.colorName === 'water';
    })
    return selected[0];
  }

  Metal(): IElement {
    const selected = this.elements.filter(e => {
      return e.colorName === 'metal';
    })
    return selected[0];
  }

  Earth(): IElement {
    const selected = this.elements.filter(e => {
      return e.colorName === 'earth';
    })
    return selected[0];
  }

  All(): IElement[] {
    return this.elements;
  }

}