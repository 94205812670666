import IBBMainCategory from './interfaces/IBBMainCategory';
import IBBMainCategoryPair from './interfaces/IBBMainCategoryPair';
import IEmotionPair from './interfaces/IEmotionPair';
import IEmotion from './interfaces/IEmotion';

export class BBMainCategoryPair implements IBBMainCategoryPair {
  place: number;
  right: IBBMainCategory;
  left: IBBMainCategory;

  constructor(place: number, left: IBBMainCategory, right: IBBMainCategory) {
    this.place = place;
    this.left = left;
    this.right = right;
  }

  findEmotionPair(place: number): IEmotionPair {
    const right: IEmotion = this.right.findEmotion(place);
    const left: IEmotion = this.left.findEmotion(place);

    return {left: left, right: right};
  }

}