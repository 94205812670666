import { BBSides } from "../enums/BBSides.enum";
import { LangCodes } from "../enums/LangCodes.enum";
import { BBLevel } from "../models/BBLevel";
import { BBMainCategory } from "../models/BBMainCategory";
import { BBMainCategoryPair } from "../models/BBMainCategoryPair";
import Emotion from "../models/Emotion";
import IBBMainCategoryPair from "../models/interfaces/IBBMainCategoryPair";
import IEmotion from "../models/interfaces/IEmotion";
import IBBLevel from '../models/interfaces/IBBLevel';
import { BBMainCategoryBodyBottom } from "../models/BBMainCategoryBodyBottom";
import { BehavioralBarometer } from '../BehavioralBarometer';

export class BBhuHUBuilder {
  build(): BehavioralBarometer {
    const tudatos = this.tudatos();
    const tudatalatti = this.tudatalatti();
    const testi = this.testi();

    return new BehavioralBarometer([tudatos, tudatalatti, testi]);
  }

  testi(): IBBLevel {
    const emoLeft1: IEmotion[] = [
      new Emotion('Összhangban lévő', 1, LangCodes.huHU),
      new Emotion('Megfelelő', 2, LangCodes.huHU),
      new Emotion('Kiegyensúlyozott', 3, LangCodes.huHU),
      new Emotion('Alkotó', 4, LangCodes.huHU),
      new Emotion('Figyelmes', 5, LangCodes.huHU),
      new Emotion('Méltányoló', 6, LangCodes.huHU),
      new Emotion('Tapintatos', 7, LangCodes.huHU),
      new Emotion('Szelíd', 8, LangCodes.huHU),
    ];
    
    const emoRight1: IEmotion[] = [
      new Emotion('Borúlátó', 1, LangCodes.huHU),
      new Emotion('Bénult', 2, LangCodes.huHU),
      new Emotion('Merev', 3, LangCodes.huHU),
      new Emotion('Fásult', 4, LangCodes.huHU),
      new Emotion('Mozdulatlan', 5, LangCodes.huHU),
      new Emotion('Érzéketlen', 6, LangCodes.huHU),
      new Emotion('Romboló', 7, LangCodes.huHU),
      new Emotion('Lekapcsolódott', 8, LangCodes.huHU),
    ];
    
    const mainCatLeft1 = new BBMainCategory('Ráhangolódás', BBSides.left, emoLeft1);
    const mainCatRight1 = new BBMainCategory('Közömbösség', BBSides.right, emoRight1);
    const mainCatPair1 = new BBMainCategoryPair(1, mainCatLeft1, mainCatRight1);
    
    const emoLeft2: IEmotion[] = [
      new Emotion('Csendes', 1, LangCodes.huHU),
      new Emotion('Biztos', 2, LangCodes.huHU),
      new Emotion('Nyugodt', 3, LangCodes.huHU),
      new Emotion('Békéslelkű', 4, LangCodes.huHU),
      new Emotion('Egységben lévő', 5, LangCodes.huHU),
      new Emotion('Teljes', 6, LangCodes.huHU),
      new Emotion('Kiteljesedett', 7, LangCodes.huHU),
      new Emotion('Egységessségben lévő', 8, LangCodes.huHU),
    ];
    
    const emoRight2: IEmotion[] = [
      new Emotion('Elhanyagolt', 1, LangCodes.huHU),
      new Emotion('Nem szeretett', 2, LangCodes.huHU),
      new Emotion('Elfogadhatatlan', 3, LangCodes.huHU),
      new Emotion('Szeret(het)etlen', 4, LangCodes.huHU),
      new Emotion('Jelentéktelen', 5, LangCodes.huHU),
      new Emotion('Búskomor', 6, LangCodes.huHU),
      new Emotion('Beteges, morbid', 7, LangCodes.huHU),
      new Emotion('Elhagyott', 8, LangCodes.huHU),
    ];
    
    const mainCatLeft2 = new BBMainCategory('Egység', BBSides.left, emoLeft2);
    const mainCatRight2 = new BBMainCategory('Elkülönülés', BBSides.right, emoRight2);
    const mainCatPair2 = new BBMainCategoryPair(2, mainCatLeft2, mainCatRight2);
    
    const emoLeft3: IEmotion[] = [
      new Emotion('Van választás', 1, LangCodes.huHU),
    ];
    
    const emoRight3: IEmotion[] = [
      new Emotion('Nincs választás', 1, LangCodes.huHU),
    ];
    
    const mainCatLeft3 = new BBMainCategoryBodyBottom('Van választás', BBSides.left, emoLeft3);
    const mainCatRight3 = new BBMainCategoryBodyBottom('Nincs választás', BBSides.right, emoRight3);
    const mainCatPair3 = new BBMainCategoryPair(3, mainCatLeft3, mainCatRight3);
    
    const categoriePairs: IBBMainCategoryPair[] = [
      mainCatPair1, mainCatPair2, mainCatPair3
    ];
    
    return new BBLevel('Testi', 3, categoriePairs);
  }

  tudatalatti(): IBBLevel {
    const emoLeft1: IEmotion[] = [
      new Emotion('Szórakoztató', 1, LangCodes.huHU),
      new Emotion('Örvendező', 2, LangCodes.huHU),
      new Emotion('Csodálatra méltó', 3, LangCodes.huHU),
      new Emotion('Vonzó', 4, LangCodes.huHU),
      new Emotion('Gyönyörködő', 5, LangCodes.huHU),
      new Emotion('Izgatott', 6, LangCodes.huHU),
      new Emotion('Életteli', 7, LangCodes.huHU),
      new Emotion('A másikban megbízó', 8, LangCodes.huHU),
    ];
    
    const emoRight1: IEmotion[] = [
      new Emotion('Csapdába ejtett', 1, LangCodes.huHU),
      new Emotion('Kipécézett', 2, LangCodes.huHU),
      new Emotion('Becsapott', 3, LangCodes.huHU),
      new Emotion('Fusztrált', 4, LangCodes.huHU),
      new Emotion('Kifosztott', 5, LangCodes.huHU),
      new Emotion('Gúnyolódó', 6, LangCodes.huHU),
      new Emotion('Bosszúvágyó', 7, LangCodes.huHU),
      new Emotion('Magábafojtó', 8, LangCodes.huHU),
    ];
    
    const mainCatLeft1 = new BBMainCategory('Lelkesedés', BBSides.left, emoLeft1);
    const mainCatRight1 = new BBMainCategory('Ellenségeskedés', BBSides.right, emoRight1);
    const mainCatPair1 = new BBMainCategoryPair(1, mainCatLeft1, mainCatRight1);
    
    const emoLeft2: IEmotion[] = [
      new Emotion('Ösztönzött', 1, LangCodes.huHU),
      new Emotion('Merész', 2, LangCodes.huHU),
      new Emotion('Védett', 3, LangCodes.huHU),
      new Emotion('Elszánt', 4, LangCodes.huHU),
      new Emotion('Bátor', 5, LangCodes.huHU),
      new Emotion('Megfontolt', 6, LangCodes.huHU),
      new Emotion('Gyengéd', 7, LangCodes.huHU),
      new Emotion('Büszke', 8, LangCodes.huHU),
    ];
    
    const emoRight2: IEmotion[] = [
      new Emotion('Cserben hagyott', 1, LangCodes.huHU),
      new Emotion('Meg nem hallgatott', 2, LangCodes.huHU),
      new Emotion('Keserű', 3, LangCodes.huHU),
      new Emotion('Csalódott', 4, LangCodes.huHU),
      new Emotion('Fenyegetett', 5, LangCodes.huHU),
      new Emotion('Lenézett', 6, LangCodes.huHU),
      new Emotion('Rémült', 7, LangCodes.huHU),
      new Emotion('Nem szívesen látott', 8, LangCodes.huHU),
    ];
    
    const mainCatLeft2 = new BBMainCategory('Önbizalom', BBSides.left, emoLeft2);
    const mainCatRight2 = new BBMainCategory('Félelem a veszteségtől', BBSides.right, emoRight2);
    const mainCatPair2 = new BBMainCategoryPair(2, mainCatLeft2, mainCatRight2);
    
    const emoLeft3: IEmotion[] = [
      new Emotion('Szerencsés', 1, LangCodes.huHU),
      new Emotion('Együttműködő', 2, LangCodes.huHU),
      new Emotion('Tevékenyen résztvevő', 3, LangCodes.huHU),
      new Emotion('Céltudatos', 4, LangCodes.huHU),
      new Emotion('Megbízható', 5, LangCodes.huHU),
      new Emotion('Törődő', 6, LangCodes.huHU),
      new Emotion('Őszinte', 7, LangCodes.huHU),
      new Emotion('Eredményes', 8, LangCodes.huHU),
    ];
    
    const emoRight3: IEmotion[] = [
      new Emotion('Elárult', 1, LangCodes.huHU),
      new Emotion('Leigázott', 2, LangCodes.huHU),
      new Emotion('Csüggedt', 3, LangCodes.huHU),
      new Emotion('Elfogadhatatlan', 4, LangCodes.huHU),
      new Emotion('Önostorozó', 5, LangCodes.huHU),
      new Emotion('Levert', 6, LangCodes.huHU),
      new Emotion('Legyőzött', 7, LangCodes.huHU),
      new Emotion('Tönkretett', 8, LangCodes.huHU),
    ];
    
    const mainCatLeft3 = new BBMainCategory('Egyenrangúság', BBSides.left, emoLeft3);
    const mainCatRight3 = new BBMainCategory('Bánat és bűntudat', BBSides.right, emoRight3);
    const mainCatPair3 = new BBMainCategoryPair(3, mainCatLeft3, mainCatRight3);
    
    const categoriePairs: IBBMainCategoryPair[] = [
      mainCatPair1, mainCatPair2, mainCatPair3
    ];
    
    return new BBLevel('Tudatalatti', 2, categoriePairs);
  }

  tudatos(): IBBLevel {
    const emoLeft1: IEmotion[] = [
      new Emotion('Választó', 1, LangCodes.huHU),
      new Emotion('Hozzáférhető', 2, LangCodes.huHU),
      new Emotion('Derülátó', 3, LangCodes.huHU),
      new Emotion('Elfogadható', 4, LangCodes.huHU),
      new Emotion('Alkamazkodó', 5, LangCodes.huHU),
      new Emotion('Méltó', 6, LangCodes.huHU),
      new Emotion('Megérdemlő', 7, LangCodes.huHU),
      new Emotion('Nyílt', 8, LangCodes.huHU),
    ];
    
    const emoRight1: IEmotion[] = [
      new Emotion('Megtámadott', 1, LangCodes.huHU),
      new Emotion('Nyugtalan', 2, LangCodes.huHU),
      new Emotion('Megkérdőjelezett', 3, LangCodes.huHU),
      new Emotion('Megterhelt', 4, LangCodes.huHU),
      new Emotion('Bosszús', 5, LangCodes.huHU),
      new Emotion('Méltatlankodó', 6, LangCodes.huHU),
      new Emotion('Szembeszegülő', 7, LangCodes.huHU),
      new Emotion('Alkalmatlan', 8, LangCodes.huHU),
    ];
    
    const mainCatLeft1 = new BBMainCategory('Elfogadás', BBSides.left, emoLeft1);
    const mainCatRight1 = new BBMainCategory('Szembenállás', BBSides.right, emoRight1);
    const mainCatPair1 = new BBMainCategoryPair(1, mainCatLeft1, mainCatRight1);
    
    const emoLeft2: IEmotion[] = [
      new Emotion('Fogékony', 1, LangCodes.huHU),
      new Emotion('Megfelelő', 2, LangCodes.huHU),
      new Emotion('Felkészült', 3, LangCodes.huHU),
      new Emotion('Felelős', 4, LangCodes.huHU),
      new Emotion('Bátorító', 5, LangCodes.huHU),
      new Emotion('Kipihent', 6, LangCodes.huHU),
      new Emotion('Erőt sugárzó', 7, LangCodes.huHU),
      new Emotion('Figyelmes', 8, LangCodes.huHU),
    ];
    
    const emoRight2: IEmotion[] = [
      new Emotion('Felháborodott', 1, LangCodes.huHU),
      new Emotion('Dühös', 2, LangCodes.huHU),
      new Emotion('Túlfeszített', 3, LangCodes.huHU),
      new Emotion('Füstölgő', 4, LangCodes.huHU),
      new Emotion('Fortyogó', 5, LangCodes.huHU),
      new Emotion('Heveskedő', 6, LangCodes.huHU),
      new Emotion('Harcbanálló', 7, LangCodes.huHU),
      new Emotion('Hisztérikus', 8, LangCodes.huHU),
    ];
    
    const mainCatLeft2 = new BBMainCategory('Hajlandóság', BBSides.left, emoLeft2);
    const mainCatRight2 = new BBMainCategory('Harag', BBSides.right, emoRight2);
    const mainCatPair2 = new BBMainCategoryPair(2, mainCatLeft2, mainCatRight2);
    
    const emoLeft3: IEmotion[] = [
      new Emotion('Elbűvölt', 1, LangCodes.huHU),
      new Emotion('Ráhangolódó', 2, LangCodes.huHU),
      new Emotion('Igényelt', 3, LangCodes.huHU),
      new Emotion('Szívesen látott', 4, LangCodes.huHU),
      new Emotion('Megértő', 5, LangCodes.huHU),
      new Emotion('Megbecsült', 6, LangCodes.huHU),
      new Emotion('Nélkülözhetetlen', 7, LangCodes.huHU),
      new Emotion('Gondoskodó', 8, LangCodes.huHU),
    ];
    
    const emoRight3: IEmotion[] = [
      new Emotion('Megbántott', 1, LangCodes.huHU),
      new Emotion('Feszült', 2, LangCodes.huHU),
      new Emotion('Megsebzett', 3, LangCodes.huHU),
      new Emotion('Kihasznált', 4, LangCodes.huHU),
      new Emotion('Mellőzött', 5, LangCodes.huHU),
      new Emotion('Visszautasított', 6, LangCodes.huHU),
      new Emotion('Hallgatag', 7, LangCodes.huHU),
      new Emotion('Megsértődött', 8, LangCodes.huHU),
    ];
    
    const mainCatLeft3 = new BBMainCategory('Érdeklődés', BBSides.left, emoLeft3);
    const mainCatRight3 = new BBMainCategory('Sértődöttség', BBSides.right, emoRight3);
    const mainCatPair3 = new BBMainCategoryPair(3, mainCatLeft3, mainCatRight3);
    
    const categoriePairs: IBBMainCategoryPair[] = [
      mainCatPair1, mainCatPair2, mainCatPair3
    ];
    
    return new BBLevel('Tudatos', 1, categoriePairs);
  }
}