import Meridian from '../models/Meridian';

const MeridianClockEn = () => {

  const meridianTimes: Meridian[] = [
    { start: 9, end: 11, meridian: `spleen-pancreas` },
    { start: 11, end: 13, meridian: `hearth` },
    { start: 13, end: 15, meridian: `small-intestine` },
    { start: 15, end: 17, meridian: `bladder` },
    { start: 17, end: 19, meridian: `kidney` },
    { start: 19, end: 21, meridian: `circulation-szex` },
    { start: 21, end: 23, meridian: `triple-warmer` },
    { start: 23, end: 1, meridian: `gallbladder` },
    { start: 1, end: 3, meridian: `liver` },
    { start: 3, end: 5, meridian: `lung` },
    { start: 5, end: 7, meridian: `large intestine` },
    { start: 7, end: 9, meridian: `stomach` },
  ];

  const selectCurrentMeridian = () => {
    const now = new Date();
    const nowHour = now.getHours();
    
    const current: Meridian[] = meridianTimes.filter((meridian: Meridian) => {
      return meridian.start == nowHour || meridian.start < nowHour
        && meridian.end > nowHour
    });

    return current[0];
  }

  return (
    <div className="card mb-3">
      <div className="card-header">
        Meridian Clock
      </div>
      <div className="card-body">
        <h4>Active: <b>{selectCurrentMeridian().meridian}</b> meridian,
          start: <b>{selectCurrentMeridian().start}</b>h,
          end: <b>{selectCurrentMeridian().end}</b>h
        </h4>
      </div>
    </div>
  )
}

export default MeridianClockEn;