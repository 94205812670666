import { BBSides } from '../enums/BBSides.enum';
import IBBMainCategory from './interfaces/IBBMainCategory';
import IEmotion from './interfaces/IEmotion';

export class BBMainCategoryBodyBottom implements IBBMainCategory {
  name: string;
  side: BBSides;
  emotions: IEmotion[] = [];

  constructor(name: string, side: BBSides, emotions: IEmotion[]) {
    this.name = name;
    this.side = side;
    this.emotions = emotions;
  }

  findEmotion(place: number): IEmotion {    
    return this.emotions[0];
  }
}