import IBBLevel from './interfaces/IBBLevel';
import IBBMainCategoryPair from './interfaces/IBBMainCategoryPair';
import IEmotionPair from './interfaces/IEmotionPair';

export class BBLevel implements IBBLevel {
  place: number;
  name: string;
  categories: IBBMainCategoryPair[];
  
  constructor(name: string, place: number, categoriePairs: IBBMainCategoryPair[]) {
    this.name = name;
    this.place = place;
    this.categories = categoriePairs;
  }

  findEmotionPair(mainCategoryPairPlace: number, emotionPlace: number): IEmotionPair {
    const mainCategoriePair = this.categories.filter(category => {
      return category.place === mainCategoryPairPlace;
    });

    if (mainCategoriePair.length === 0)
      throw new Error(`Main category is not found, in this: '${mainCategoryPairPlace}' place number`);

    return mainCategoriePair[0].findEmotionPair(emotionPlace);
  }

}