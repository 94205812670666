import { BBSides } from '../enums/BBSides.enum';
import IBBMainCategory from './interfaces/IBBMainCategory';
import IEmotion from './interfaces/IEmotion';

export class BBMainCategory implements IBBMainCategory {
  name: string;
  side: BBSides;
  emotions: IEmotion[] = [];

  constructor(name: string, side: BBSides, emotions: IEmotion[]) {
    this.name = name;
    this.side = side;
    this.emotions = emotions;
  }

  findEmotion(place: number): IEmotion {
    let result: IEmotion[] = this.emotions.filter(emotion => {
      return emotion.place === place;
    });

    if (result.length === 0)
      throw new Error(`Emotion is not found in this: '${place}' place number`);

    return result[0];
  }


}