export class TestedValueValidator {
  isValid: boolean = true;
  invalidClassName: string = 'is-invalid';

  inputSelectElements: HTMLSelectElement[] = [];

  constructor(inputSelectElements: HTMLSelectElement[]) {
    this.inputSelectElements = inputSelectElements;
  }

  validation() {
    this.removeIsInvalidClass();
    for (const element of this.inputSelectElements) {
      if (element.value === "0") {
        element.classList.add(this.invalidClassName);
        this.isValid = false;
      }
    }
  }

  private removeIsInvalidClass(): void {
    this.isValid = true;
    for (const element of this.inputSelectElements) {
      element.classList.remove(this.invalidClassName);      
    }
  }

  reset() {
    this.isValid = true;
    for (const element of this.inputSelectElements) {
      element.classList.remove(this.invalidClassName);  
      element.value = '0';    
    }
  }
}