import { useRef } from "react";
import { TestedValueValidator } from "../../modules/bb/TestedValueValidator";
import ITestedValues from "../../modules/bb/models/interfaces/ITestedValues";
import { BBhuHUBuilder } from "../../modules/bb/builders/BBhuHUBuilder";
import { ResultTable } from "../../modules/bb/views/ResultTable";
import { ResultPhrases } from "../../modules/bb/views/ResultPhrases";
import { BBenUSBuilder } from "../../modules/bb/builders/BBenUSBuilder";
import { ResultTableEnUS } from "../../modules/bb/views/enUS/ResultTableEnUS";
import { ResultPhrasesEnUS } from "../../modules/bb/views/enUS/ResultPhrasesEnUS";
import Header from "../../components/Header";
import Container from "../../components/Container";
import HeaderEn from "../../components/HeaderEn";
import MeridianClockEn from "../../components/MeridianClockEn";


const HomeEn = () => {

  const selectLevelsRef = useRef<HTMLSelectElement>(null);
  const selectMainCategoryPair = useRef<HTMLSelectElement>(null);
  const selectSubcategorie = useRef<HTMLSelectElement>(null);

  

  const submitHandler = (event: React.MouseEvent<HTMLButtonElement>): void => {
    console.log(selectLevelsRef.current?.value);
    console.log(selectMainCategoryPair.current?.value);
    console.log(selectSubcategorie.current?.value);

    // // valdation  
    const validationHandler = new TestedValueValidator([
      selectLevelsRef.current as HTMLSelectElement,
      selectMainCategoryPair.current as HTMLSelectElement, 
      selectSubcategorie.current as HTMLSelectElement
    ]);

    validationHandler.validation();

    if (!validationHandler.isValid) {
      return;
    }

    if (validationHandler.isValid) {
      const testedValues: ITestedValues = {
        level: Number(selectLevelsRef.current?.value),
        categoryPair: Number(selectMainCategoryPair.current?.value),
        emotion: Number(selectSubcategorie.current?.value)
      };

      const bb = new BBenUSBuilder().build();
      const resultEm = bb.sixForThePriceOfOne(testedValues);
      console.log(resultEm);

      const resultWrapper: HTMLDivElement = document.querySelector('#resultWrapper') as HTMLDivElement;
      const resultPhrasesWrapper: HTMLDivElement = document.querySelector('#resultPhrasesWrapper') as HTMLDivElement;

      const resultTable = new ResultTableEnUS(resultWrapper);
      const resultPhrases = new ResultPhrasesEnUS(resultPhrasesWrapper);

      resultPhrases.render(resultEm);
      resultTable.render(resultEm);
    }
  }

  const resetHandler = (event: React.MouseEvent<HTMLButtonElement>): void => {
    console.log(selectLevelsRef.current?.value);
    console.log(selectMainCategoryPair.current?.value);
    console.log(selectSubcategorie.current?.value);
    // // valdation  
    const validationHandler = new TestedValueValidator([
      selectLevelsRef.current as HTMLSelectElement,
      selectMainCategoryPair.current as HTMLSelectElement, 
      selectSubcategorie.current as HTMLSelectElement
    ]);
    validationHandler.reset();

    const resultWrapper: HTMLDivElement = document.querySelector('#resultWrapper') as HTMLDivElement;
    const resultPhrasesWrapper: HTMLDivElement = document.querySelector('#resultPhrasesWrapper') as HTMLDivElement;

    const resultTable = new ResultTableEnUS(resultWrapper);
    const resultPhrases = new ResultPhrasesEnUS(resultPhrasesWrapper);

    resultTable.reset();
    resultPhrases.reset();
  }

  return (
    <div>
      <HeaderEn />
      <Container>
        <MeridianClockEn />
    <div>
        <h1>One Brain Behavioral Barometer</h1>
        <div className="card">
          <h5 className="card-header">Select the tested values!</h5>
          <div className="card-body">
              <div className="form-floating p-2">
                <select 
                  ref={selectLevelsRef}
                  className="form-select" id="selectLevels" aria-label="Floating label select example">
                  <option value={'0'}>Open this select menu</option>
                  <option value={'1'}>1. Conscious</option>
                  <option value={'2'}>2. Subconscious</option>
                  <option value={'3'}>3. Physical</option>
                </select>
                <label htmlFor="selectLevels">Levels</label>
                <div className="invalid-feedback">Select the tested value!</div>
              </div>
              <div className="form-floating p-2">
                <select 
                  ref={selectMainCategoryPair}
                  className="form-select" id="selectMainCategoryPairs" aria-label="Floating label select example">
                  <option value={'0'}>Open this select menu</option>
                  <option value={'1'}>1.</option>
                  <option value={'2'}>2.</option>
                  <option value={'3'}>3.</option>
                </select>
                <label htmlFor="selectMainCategoryPairs">Maincategorie pairs (left-right)</label>
                <div className="invalid-feedback">Select the tested value!</div>
              </div>
              <div className="form-floating p-2">
                <select 
                  ref={selectSubcategorie}
                  className="form-select" id="selectSubcategories" aria-label="Floating label select example">
                  <option value={'0'}>Open this select menu</option>
                  <option value={'1'}>1.</option>
                  <option value={'2'}>2.</option>
                  <option value={'3'}>3.</option>
                  <option value={'4'}>4.</option>
                  <option value={'5'}>5.</option>
                  <option value={'6'}>6.</option>
                  <option value={'7'}>7.</option>
                  <option value={'8'}>8.</option>
                </select>
                <label htmlFor="selectSubcategories">Emotions (subcategories)</label>
                <div className="invalid-feedback">Select the tested value!</div>
              </div>
            <div className="btn-group" role="group" aria-label="Basic example">
              <button 
                onClick={submitHandler}
                id="sixForThePriceOfOneButton" type="button" className="btn btn-lg btn-primary">Six for the price of one!</button>
              <button 
                onClick={resetHandler}
                id="resetButton" type="button" className="btn btn-lg btn-outline-warning">Reset</button>
            </div>
          </div>        
        </div>  
        <div id="resultWrapper"></div>    
        <div id="resultPhrasesWrapper"></div>    
    </div>

      </Container>      
    </div>
  );
}

export default HomeEn;