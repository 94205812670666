import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import HomeEn from './pages/en/HomeEn';
import PageWrapper from './components/PageWrapper';
import FiveElementsPage from './pages/FiveElementsPage';
import FiveElementsPageHU from './pages/FiveElementsPageHU';
import Formats from './pages/Formats';

function App() {

  return (
    <PageWrapper>
      <Routes>
        <Route path='/' element={<HomeEn />} />
        <Route path='/hu' element={<Home />} />
        <Route path='/formats' element={<Formats />} />
        <Route path='/five_elements' element={<FiveElementsPage />} />
        <Route path='/hu/five_elements' element={<FiveElementsPageHU />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </PageWrapper>   
    
  );
}

export default App;
