import Result from "../models/Result";
import IView from "./interfaces/IView";

export class ResultPhrases implements IView {
  constructor(private wrapperElement: HTMLElement) {

  }

  private element = (result: Result): string => {
    let tudatosBal: string = result.conscious.left.name;
    let tudatosJobb: string = result.conscious.right.name;
    let tudatalattiBal: string = result.subconscious.left.name;
    let tudatalattiJobb: string = result.subconscious.right.name;
    let testiBal: string = result.physical.left.name;
    let testiJobb: string = result.physical.right.name;

    return `<ul class="list-group">
      <li class="list-group-item list-group-item-warning">Ebben az ügyben gyerekkorod óta arra vágytál, hogy (VB Testi Bal oldala) <b>${testiBal}</b></li>
      <li class="list-group-item list-group-item-warning">Ehelyett azt tapasztaltad, hogy (VB Testi Jobb oldala) <b>${testiJobb}</b></li>
      <li class="list-group-item list-group-item-info">A múltban azt szeretted volna, hogy (VB Tudatalatti Bal oldala) <b>${tudatalattiBal}</b></li>
      <li class="list-group-item list-group-item-info">Ehelyett azt tapasztaltad, hogy (VB Tudatalatti Jobb oldala) <b>${tudatalattiJobb}</b></li>
      <li class="list-group-item list-group-item-danger">Emiatt a jelenben arra vágysz, hogy (VB Tudatos Bal oldala) <b>${tudatosBal}</b></li>
      <li class="list-group-item list-group-item-danger">De azt tapasztalod, hogy (VB Tudatos Jobb oldala) <b>${tudatosJobb}</b></li>
    </ul>`
  } 


  render = (result: Result): void => {
    this.wrapperElement.innerHTML = this.element(result);
  }

  reset = (): void => {
    this.wrapperElement.innerHTML = '';
  }

}