import IBBLevel from './models/interfaces/IBBLevel';
import IEmotionPair from './models/interfaces/IEmotionPair';
import Result from './models/Result';
import ITestedValues from './models/interfaces/ITestedValues';

export class BehavioralBarometer {
  levels: IBBLevel[];

  constructor(levels: IBBLevel[]) {
    this.levels = levels;
  }

  sixForThePriceOfOne(values: ITestedValues): Result {
    const testedLevel: IBBLevel = this.selectLevel(values.level);
    const testedEmotionPair = testedLevel.findEmotionPair(values.categoryPair, values.emotion);
    
    const conscious: IEmotionPair = this.selectLevel(1)
      .findEmotionPair(values.categoryPair, values.emotion);
    const subconscious: IEmotionPair = this.selectLevel(2)
      .findEmotionPair(values.categoryPair, values.emotion);
    const physical: IEmotionPair = this.selectLevel(3)
      .findEmotionPair(values.categoryPair, values.emotion);

    return {
      conscious: conscious, 
      subconscious: subconscious, 
      physical: physical,
      testedValues: values,
      testedEmotionPair: testedEmotionPair,
    };
  }

  selectLevel = (place: number): IBBLevel => {
    const levels = this.levels.filter(level => {
      return level.place === place;
    });

    if (levels.length === 0)
      throw new Error(`Level is not found in this: '${place}' place number`);

    return levels[0];
  }
}