import { BBSides } from "../enums/BBSides.enum";
import { LangCodes } from "../enums/LangCodes.enum";
import { BBLevel } from "../models/BBLevel";
import { BBMainCategory } from "../models/BBMainCategory";
import { BBMainCategoryPair } from "../models/BBMainCategoryPair";
import Emotion from "../models/Emotion";
import IBBMainCategoryPair from "../models/interfaces/IBBMainCategoryPair";
import IEmotion from "../models/interfaces/IEmotion";
import IBBLevel from '../models/interfaces/IBBLevel';
import { BBMainCategoryBodyBottom } from "../models/BBMainCategoryBodyBottom";
import { BehavioralBarometer } from '../BehavioralBarometer';

export class BBenUSBuilder {
  build(): BehavioralBarometer {
    const conscious = this.conscious();
    const subconscious = this.subconscious();
    const physical = this.physical();

    return new BehavioralBarometer([conscious, subconscious, physical]);
  }

  physical(): IBBLevel {
    const emoLeft1: IEmotion[] = [
      new Emotion('In tune with', 1, LangCodes.enUS),
      new Emotion('Congruent', 2, LangCodes.enUS),
      new Emotion('In balance', 3, LangCodes.enUS),
      new Emotion('Creative', 4, LangCodes.enUS),
      new Emotion('Perceptive', 5, LangCodes.enUS),
      new Emotion('Appreciative', 6, LangCodes.enUS),
      new Emotion('Tender', 7, LangCodes.enUS),
      new Emotion('Gentle', 8, LangCodes.enUS),
    ];
    
    const emoRight1: IEmotion[] = [
      new Emotion('Pessimistic', 1, LangCodes.enUS),
      new Emotion('Immobilized', 2, LangCodes.enUS),
      new Emotion('Rigid', 3, LangCodes.enUS),
      new Emotion('Numb', 4, LangCodes.enUS),
      new Emotion('Stagnant', 5, LangCodes.enUS),
      new Emotion('Unfeeling', 6, LangCodes.enUS),
      new Emotion('Destructive', 7, LangCodes.enUS),
      new Emotion('Disconnected', 8, LangCodes.enUS),
    ];
    
    const mainCatLeft1 = new BBMainCategory('Attunement', BBSides.left, emoLeft1);
    const mainCatRight1 = new BBMainCategory('Indiference', BBSides.right, emoRight1);
    const mainCatPair1 = new BBMainCategoryPair(1, mainCatLeft1, mainCatRight1);
    
    const emoLeft2: IEmotion[] = [
      new Emotion('Quiet', 1, LangCodes.enUS),
      new Emotion('Safe', 2, LangCodes.enUS),
      new Emotion('Calm', 3, LangCodes.enUS),
      new Emotion('At peace', 4, LangCodes.enUS),
      new Emotion('Unified', 5, LangCodes.enUS),
      new Emotion('Completed', 6, LangCodes.enUS),
      new Emotion('Fulfilled', 7, LangCodes.enUS),
      new Emotion('At-one-ment', 8, LangCodes.enUS),
    ];
    
    const emoRight2: IEmotion[] = [
      new Emotion('Uncared for', 1, LangCodes.enUS),
      new Emotion('Unloved', 2, LangCodes.enUS),
      new Emotion('Unacceptable', 3, LangCodes.enUS),
      new Emotion('Loveness/unlovable', 4, LangCodes.enUS),
      new Emotion('Unimportant', 5, LangCodes.enUS),
      new Emotion('Melancholy', 6, LangCodes.enUS),
      new Emotion('Morbid', 7, LangCodes.enUS),
      new Emotion('Deserted', 8, LangCodes.enUS),
    ];
    
    const mainCatLeft2 = new BBMainCategory('Oneness', BBSides.left, emoLeft2);
    const mainCatRight2 = new BBMainCategory('Separation', BBSides.right, emoRight2);
    const mainCatPair2 = new BBMainCategoryPair(2, mainCatLeft2, mainCatRight2);
    
    const emoLeft3: IEmotion[] = [
      new Emotion('Choice', 1, LangCodes.enUS),
    ];
    
    const emoRight3: IEmotion[] = [
      new Emotion('No Choice', 1, LangCodes.huHU),
    ];
    
    const mainCatLeft3 = new BBMainCategoryBodyBottom('Choice', BBSides.left, emoLeft3);
    const mainCatRight3 = new BBMainCategoryBodyBottom('No Choice', BBSides.right, emoRight3);
    const mainCatPair3 = new BBMainCategoryPair(3, mainCatLeft3, mainCatRight3);
    
    const categoriePairs: IBBMainCategoryPair[] = [
      mainCatPair1, mainCatPair2, mainCatPair3
    ];
    
    return new BBLevel('Body', 3, categoriePairs);
  }

  subconscious(): IBBLevel {
    const emoLeft1: IEmotion[] = [
      new Emotion('Amused', 1, LangCodes.enUS),
      new Emotion('Jubilant', 2, LangCodes.enUS),
      new Emotion('Admirable', 3, LangCodes.enUS),
      new Emotion('Attractive', 4, LangCodes.enUS),
      new Emotion('Delighted', 5, LangCodes.enUS),
      new Emotion('Excited', 6, LangCodes.enUS),
      new Emotion('Alive', 7, LangCodes.enUS),
      new Emotion('Trusting', 8, LangCodes.enUS),
    ];
    
    const emoRight1: IEmotion[] = [
      new Emotion('Trapped', 1, LangCodes.enUS),
      new Emotion('Picked-on', 2, LangCodes.enUS),
      new Emotion('Put-upon', 3, LangCodes.enUS),
      new Emotion('Fustrated', 4, LangCodes.enUS),
      new Emotion('Deprived', 5, LangCodes.enUS),
      new Emotion('Sarcastic', 6, LangCodes.enUS),
      new Emotion('Vindictive', 7, LangCodes.enUS),
      new Emotion('With-holding', 8, LangCodes.enUS),
    ];
    
    const mainCatLeft1 = new BBMainCategory('Enthuasiasm', BBSides.left, emoLeft1);
    const mainCatRight1 = new BBMainCategory('Hostility', BBSides.right, emoRight1);
    const mainCatPair1 = new BBMainCategoryPair(1, mainCatLeft1, mainCatRight1);
    
    const emoLeft2: IEmotion[] = [
      new Emotion('Motivated', 1, LangCodes.enUS),
      new Emotion('Daring', 2, LangCodes.enUS),
      new Emotion('Protected', 3, LangCodes.enUS),
      new Emotion('Bold', 4, LangCodes.enUS),
      new Emotion('Brave', 5, LangCodes.enUS),
      new Emotion('Considered', 6, LangCodes.enUS),
      new Emotion('Affectionate', 7, LangCodes.enUS),
      new Emotion('Proud', 8, LangCodes.enUS),
    ];
    
    const emoRight2: IEmotion[] = [
      new Emotion('Let-down', 1, LangCodes.enUS),
      new Emotion('Not-heard', 2, LangCodes.enUS),
      new Emotion('Bitter', 3, LangCodes.enUS),
      new Emotion('Disappointed', 4, LangCodes.enUS),
      new Emotion('Threatened', 5, LangCodes.enUS),
      new Emotion('Over-looked', 6, LangCodes.enUS),
      new Emotion('Frightened', 7, LangCodes.enUS),
      new Emotion('Unwelcome', 8, LangCodes.enUS),
    ];
    
    const mainCatLeft2 = new BBMainCategory('Assurance', BBSides.left, emoLeft2);
    const mainCatRight2 = new BBMainCategory('Fear of loss', BBSides.right, emoRight2);
    const mainCatPair2 = new BBMainCategoryPair(2, mainCatLeft2, mainCatRight2);
    
    const emoLeft3: IEmotion[] = [
      new Emotion('Lucky', 1, LangCodes.enUS),
      new Emotion('Co-operative', 2, LangCodes.enUS),
      new Emotion('Involved', 3, LangCodes.enUS),
      new Emotion('Purposeful', 4, LangCodes.enUS),
      new Emotion('Reliable', 5, LangCodes.enUS),
      new Emotion('Concerned', 6, LangCodes.enUS),
      new Emotion('Sincere', 7, LangCodes.enUS),
      new Emotion('Productive', 8, LangCodes.enUS),
    ];
    
    const emoRight3: IEmotion[] = [
      new Emotion('Betrayed', 1, LangCodes.enUS),
      new Emotion('Conquered', 2, LangCodes.enUS),
      new Emotion('Discouraged', 3, LangCodes.enUS),
      new Emotion('Unacceptable', 4, LangCodes.enUS),
      new Emotion('Self-punishing', 5, LangCodes.enUS),
      new Emotion('Despondent', 6, LangCodes.enUS),
      new Emotion('Defeated', 7, LangCodes.enUS),
      new Emotion('Ruined', 8, LangCodes.enUS),
    ];
    
    const mainCatLeft3 = new BBMainCategory('Equality', BBSides.left, emoLeft3);
    const mainCatRight3 = new BBMainCategory('Grief and guild', BBSides.right, emoRight3);
    const mainCatPair3 = new BBMainCategoryPair(3, mainCatLeft3, mainCatRight3);
    
    const categoriePairs: IBBMainCategoryPair[] = [
      mainCatPair1, mainCatPair2, mainCatPair3
    ];
    
    return new BBLevel('Subconscious', 2, categoriePairs);
  }

  conscious(): IBBLevel {
    const emoLeft1: IEmotion[] = [
      new Emotion('Choosing to', 1, LangCodes.enUS),
      new Emotion('Approachable', 2, LangCodes.enUS),
      new Emotion('Optimistic', 3, LangCodes.enUS),
      new Emotion('Acceptable', 4, LangCodes.enUS),
      new Emotion('Adaptable', 5, LangCodes.enUS),
      new Emotion('Worthy', 6, LangCodes.enUS),
      new Emotion('Deserving', 7, LangCodes.enUS),
      new Emotion('Open', 8, LangCodes.enUS),
    ];
    
    const emoRight1: IEmotion[] = [
      new Emotion('Attacked', 1, LangCodes.enUS),
      new Emotion('Bothered', 2, LangCodes.enUS),
      new Emotion('Questioned', 3, LangCodes.enUS),
      new Emotion('Burdened', 4, LangCodes.enUS),
      new Emotion('Annoyed', 5, LangCodes.enUS),
      new Emotion('Indignant', 6, LangCodes.enUS),
      new Emotion('Opposing', 7, LangCodes.enUS),
      new Emotion('Inadequate', 8, LangCodes.enUS),
    ];
    
    const mainCatLeft1 = new BBMainCategory('Acceptance', BBSides.left, emoLeft1);
    const mainCatRight1 = new BBMainCategory('Antagonism', BBSides.right, emoRight1);
    const mainCatPair1 = new BBMainCategoryPair(1, mainCatLeft1, mainCatRight1);
    
    const emoLeft2: IEmotion[] = [
      new Emotion('Receptive', 1, LangCodes.huHU),
      new Emotion('Adequate', 2, LangCodes.huHU),
      new Emotion('Prepared', 3, LangCodes.huHU),
      new Emotion('Answerable', 4, LangCodes.huHU),
      new Emotion('Encouraging', 5, LangCodes.huHU),
      new Emotion('Refreshed', 6, LangCodes.huHU),
      new Emotion('Invigorated', 7, LangCodes.huHU),
      new Emotion('Aware', 8, LangCodes.huHU),
    ];
    
    const emoRight2: IEmotion[] = [
      new Emotion('Incensed', 1, LangCodes.huHU),
      new Emotion('Furious', 2, LangCodes.huHU),
      new Emotion('Over-wrought', 3, LangCodes.huHU),
      new Emotion('Fuming', 4, LangCodes.huHU),
      new Emotion('Seething', 5, LangCodes.huHU),
      new Emotion('Fiery', 6, LangCodes.huHU),
      new Emotion('Belligerent', 7, LangCodes.huHU),
      new Emotion('Hysterical', 8, LangCodes.huHU),
    ];
    
    const mainCatLeft2 = new BBMainCategory('Willing', BBSides.left, emoLeft2);
    const mainCatRight2 = new BBMainCategory('Anger', BBSides.right, emoRight2);
    const mainCatPair2 = new BBMainCategoryPair(2, mainCatLeft2, mainCatRight2);
    
    const emoLeft3: IEmotion[] = [
      new Emotion('Fascinated', 1, LangCodes.huHU),
      new Emotion('Tuned-in', 2, LangCodes.huHU),
      new Emotion('Needed', 3, LangCodes.huHU),
      new Emotion('Welcomed', 4, LangCodes.huHU),
      new Emotion('Understanding', 5, LangCodes.huHU),
      new Emotion('Appreciated', 6, LangCodes.huHU),
      new Emotion('Essential', 7, LangCodes.huHU),
      new Emotion('Caring', 8, LangCodes.huHU),
    ];
    
    const emoRight3: IEmotion[] = [
      new Emotion('Hurt', 1, LangCodes.huHU),
      new Emotion('Embarrassed', 2, LangCodes.huHU),
      new Emotion('Wounded', 3, LangCodes.huHU),
      new Emotion('Used/abused/confused', 4, LangCodes.huHU),
      new Emotion('Unappreciated', 5, LangCodes.huHU),
      new Emotion('Rejected', 6, LangCodes.huHU),
      new Emotion('Dumb', 7, LangCodes.huHU),
      new Emotion('Offended', 8, LangCodes.huHU),
    ];
    
    const mainCatLeft3 = new BBMainCategory('Interest', BBSides.left, emoLeft3);
    const mainCatRight3 = new BBMainCategory('Resentment', BBSides.right, emoRight3);
    const mainCatPair3 = new BBMainCategoryPair(3, mainCatLeft3, mainCatRight3);
    
    const categoriePairs: IBBMainCategoryPair[] = [
      mainCatPair1, mainCatPair2, mainCatPair3
    ];
    
    return new BBLevel('Conscious', 1, categoriePairs);
  }
}