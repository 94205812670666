import { IElement } from "../models/IElement";
import { IFiveElementsInterator } from "./interfaces/IFiveElementsInterator";

export class KOCycle implements IFiveElementsInterator {
  private counter: number = 0;
  private current: IElement;

  constructor(private elements: IElement[]) {
    if (elements.length !== 5) {
      throw new Error('Five elements length not equal 5!');
    }

    this.elements = elements;
    this.current = elements[0];
  }

  Next(): void {
    this.counter = this.counter + 2;

    if (this.counter > (this.elements.length -1)) {
      this.counter = this.counter - (this.elements.length);
    }

    this.current = this.elements[this.counter];
  }

  Current(): IElement {
    return this.current;
  }

  Rewind(): void {
    this.counter = 0;
    this.current = this.elements[this.counter];
  }
}