import Meridian from '../models/Meridian';

const MeridianClock = () => {

  const meridianTimes: Meridian[] = [
    { start: 9, end: 11, meridian: `lép-hasnyálmirigy` },
    { start: 11, end: 13, meridian: `szív` },
    { start: 13, end: 15, meridian: `vékonybél` },
    { start: 15, end: 17, meridian: `hólyag` },
    { start: 17, end: 19, meridian: `vese` },
    { start: 19, end: 21, meridian: `keringés-szex` },
    { start: 21, end: 23, meridian: `hármas-melegítő` },
    { start: 23, end: 1, meridian: `epehólyag` },
    { start: 1, end: 3, meridian: `máj` },
    { start: 3, end: 5, meridian: `tüdő` },
    { start: 5, end: 7, meridian: `vastagbél` },
    { start: 7, end: 9, meridian: `gyomor` },
  ];

  const selectCurrentMeridian = () => {
    const now = new Date();
    const nowHour = now.getHours();
    
    const current: Meridian[] = meridianTimes.filter((meridian: Meridian) => {
      return meridian.start == nowHour || meridian.start < nowHour
        && meridian.end > nowHour
    });

    return current[0];
  }

  return (
    <div className="card mb-3">
      <div className="card-header">
        Meridián Óra
      </div>
      <div className="card-body">
        <h4>Aktív: <b>{selectCurrentMeridian().meridian}</b> meridián,
          kezdődik: <b>{selectCurrentMeridian().start}</b> óra,
          befejeződik: <b>{selectCurrentMeridian().end}</b> órakkor
        </h4>
      </div>
    </div>
  )
}

export default MeridianClock;