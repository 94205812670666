import React, { FC, PropsWithChildren } from "react"

const Container: FC<PropsWithChildren> = ({children}) => {
  return (
    <div className="container">
      {children}
    </div>
  );
}

export default Container;