import BBarometer from "../components/BBarometer";
import Container from "../components/Container";
import Header from "../components/Header";
import MeridianClock from "../components/MeridianClock";

const Home = () => {

  

  return (
    <div>
      <Header />
      <Container>
        <MeridianClock />
        <BBarometer />
      </Container>      
    </div>
      
    
  );
}

export default Home;