import { NavLink, Link } from "react-router-dom";

const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg mb-4 text-bg-success">
      <div className="container">
        <Link className="navbar-brand text-white" to={'/hu'}>Kineziológia Szerszámkészlet</Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {/* <li className="nav-item">
              <NavLink className="nav-link text-white" aria-current="page" to={'/'}>Home</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link text-white" to={'/'}>Link</NavLink>
            </li> */}
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle text-white" href="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Nyelvek
              </a>
              <ul className="dropdown-menu">
                <li><NavLink className="dropdown-item" to={'/hu'}>Magyar</NavLink></li>
                <li><NavLink className="dropdown-item" to={'/'}>Angol</NavLink></li>
                {/* <li><hr className="dropdown-divider" /></li>
                <li><a className="dropdown-item" href="#">Something else here</a></li> */}
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle text-white" href="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Touch for Health
              </a>
              <ul className="dropdown-menu">
                <li><NavLink className="dropdown-item" to={'/hu/five_elements'}>Öt elem ciklusok</NavLink></li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle text-white" href="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Energetikai
              </a>
              <ul className="dropdown-menu">
                <li><NavLink className="dropdown-item" to={'/formats'}>Képletek</NavLink></li>
              </ul>
            </li>
          </ul>

        </div>
      </div>
    </nav>
  );
}

export default Header;