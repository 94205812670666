import IEmotion from './interfaces/IEmotion';
import { LangCodes } from '../enums/LangCodes.enum';

export default class Emotion implements IEmotion {
  place: number;
  name: string;
  langCode: LangCodes;

  constructor(name: string, place: number, langCode: LangCodes) {
    this.name = name;
    this.place = place;
    this.langCode = langCode;
  }

}